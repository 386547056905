import introspectedSchema from "@/generated/introspection.json";
import { useAuthContext } from "@/hooks/useAuthContext";
import { authExchange } from "@/lib/urql/authExchange";
import { makeRefreshQueriesExchange } from "@/lib/urql/refreshExchange";
import TTLCache from "@isaacs/ttlcache";
import { cacheExchange, offlineExchange } from "@urql/exchange-graphcache";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { persistedExchange } from "@urql/exchange-persisted";
import { refocusExchange } from "@urql/exchange-refocus";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { useEffect, useMemo } from "react";
import { Client, createClient, fetchExchange, mapExchange } from "urql";

const storage = makeDefaultStorage({
    idbName: "stackables-v1", // The name of the IndexedDB database
    maxAge: 7, // The maximum age of the persisted data in days
});

const timeoutInMinutes = (minutes: number) => minutes * 60 * 1000;
const cache = new TTLCache<
    string,
    { client: Client; refresh: () => void; stats: { requests: number; errors: number } }
>({
    ttl: timeoutInMinutes(60),
});

export function getCachedClient(endpoint: string) {
    return cache.get(endpoint);
}

export function useCachedUrqlClient(endpoint: string) {
    const auth = useAuthContext();

    useEffect(() => {
        if (!auth.isAuthenticated) {
            cache.clear();
        }

        cache.setTTL(endpoint, timeoutInMinutes(60));
        return () => {
            cache.setTTL(endpoint, timeoutInMinutes(10));
        };
    }, [auth, endpoint]);

    return useMemo(() => {
        let cached = cache.get(endpoint);
        if (cached) {
            return cached;
        }

        const { refreshExchange, refresh } = makeRefreshQueriesExchange();

        const stats = { requests: 0, errors: 0 };

        const cacheExchangeImplementation =
            auth.token?.td === true
                ? offlineExchange({
                      schema: introspectedSchema,
                      storage,
                  })
                : cacheExchange({
                      schema: introspectedSchema,
                  });

        const exchanges = [
            refreshExchange,
            refocusExchange(),
            requestPolicyExchange({
                // The amount of time in ms that has to go by before upgrading, default is 5 minutes.
                ttl: 60 * 1000, // 1 minute.
            }),
            cacheExchangeImplementation,
        ];

        const client = createClient({
            url: `${endpoint}`,
            exchanges: [
                ...exchanges,
                mapExchange({
                    onResult(result) {
                        stats.requests++;
                        return result;
                    },
                    onError(e) {
                        stats.errors++;
                        console.error(e);
                    },
                }),
                authExchange(auth),
                persistedExchange({
                    enforcePersistedQueries: true,
                    enableForMutation: true,
                    generateHash: (_, document) =>
                        Promise.resolve(
                            (document as unknown as { __meta__: { hash: string } })?.["__meta__"]?.["hash"]
                        ),
                    preferGetForPersistedQueries: true,
                }),
                fetchExchange,
            ],
            suspense: true,
            requestPolicy: "cache-first",
        });

        cached = { client, refresh, stats };

        cache.set(endpoint, cached);

        return cached;
    }, [auth, endpoint]);
}

export function useCachedUrqlRefresh() {
    return {
        refresh: () => {
            for (const { refresh } of cache.values()) {
                refresh();
            }
        },
        stats: (reset = false) => {
            const keys = [...cache.keys()];
            return Object.fromEntries(
                keys.map((k) => {
                    const used = [k, cache.get(k)?.stats];
                    if (reset) {
                        cache.get(k)!.stats.requests = 0;
                        cache.get(k)!.stats.errors = 0;
                    }
                    return used;
                })
            );
        },
    };
}

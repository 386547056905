import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useLogoutMutation } from "@/hooks/useLogoutMutation";
import { useMe } from "@/hooks/useMe";
import { Menu, Package2, Search } from "lucide-react";
import { Link } from "react-router-dom";

export function Header() {
    const { desktop: isDesktop } = useBreakpoints();
    const handleLogout = useLogoutMutation();
    const me = useMe();

    return (
        <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
            <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
                <Link to="/" className="flex items-center gap-2 text-lg font-semibold md:text-base">
                    <Package2 className="h-6 w-6" />
                    <span className="sr-only">Acme Inc</span>
                </Link>
                <Link to="/" className="text-muted-foreground transition-colors hover:text-foreground">
                    Dashboard
                </Link>
                <Link to="/" className="text-muted-foreground transition-colors hover:text-foreground">
                    Orders
                </Link>
                <Link to="/" className="text-muted-foreground transition-colors hover:text-foreground">
                    Products
                </Link>
                <Link to="/" className="text-muted-foreground transition-colors hover:text-foreground">
                    Customers
                </Link>
                <Link to="/" className="text-foreground transition-colors hover:text-foreground">
                    Settings
                </Link>
            </nav>
            <Sheet>
                <SheetTrigger asChild>
                    <Button variant="outline" size="icon" className="shrink-0 md:hidden">
                        <Menu className="h-5 w-5" />
                        <span className="sr-only">Toggle navigation menu</span>
                    </Button>
                </SheetTrigger>
                <SheetContent side="left">
                    <nav className="grid gap-6 text-lg font-medium">
                        <Link to="/" className="flex items-center gap-2 text-lg font-semibold">
                            <Package2 className="h-6 w-6" />
                            <span className="sr-only">Acme Inc</span>
                        </Link>
                        <Link to="/" className="text-muted-foreground hover:text-foreground">
                            Dashboard
                        </Link>
                        <Link to="/" className="text-muted-foreground hover:text-foreground">
                            Orders
                        </Link>
                        <Link to="/" className="text-muted-foreground hover:text-foreground">
                            Products
                        </Link>
                        <Link to="/" className="text-muted-foreground hover:text-foreground">
                            Customers
                        </Link>
                        <Link to="/" className="hover:text-foreground">
                            Settings
                        </Link>
                    </nav>
                </SheetContent>
            </Sheet>
            <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
                <form className="ml-auto flex-1 sm:flex-initial">
                    <div className="relative">
                        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            type="search"
                            placeholder="Search products..."
                            className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
                        />
                    </div>
                </form>
                {isDesktop && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="secondary" size="icon" className="rounded-full hover:bg-slate-300">
                                <Avatar className="h-8 w-8">
                                    {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                    <AvatarFallback>
                                        {me?.firstName?.substring(0, 1)}
                                        {me?.lastName?.substring(0, 1)}
                                    </AvatarFallback>
                                </Avatar>
                                <span className="sr-only">Toggle user menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="min-w-60">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <Link to="/-/settings">Settings</Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <button onClick={handleLogout} className="w-full">
                                    Logout
                                </button>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
                {!isDesktop && (
                    <Button variant="secondary" size="icon" className="rounded-full hover:bg-slate-300" asChild>
                        <Link to="/-/settings">
                            <Avatar className="h-8 w-8">
                                {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                <AvatarFallback>
                                    {me?.firstName?.substring(0, 1)}
                                    {me?.lastName?.substring(0, 1)}
                                </AvatarFallback>
                            </Avatar>
                        </Link>
                    </Button>
                )}
            </div>
        </header>
    );
}

import { useCachedUrqlRefresh } from "@/components/UrqlProviderContext/hooks/useCachedUrqlClient";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import type { PropsWithChildren } from "react";
import SimplePullToRefresh from "react-simple-pull-to-refresh";

export function PullToRefresh({ children }: PropsWithChildren) {
    const { refresh } = useCachedUrqlRefresh();
    const touch = useMediaQuery("(hover: none)");

    const handleRefresh = async () => {
        refresh();
        await new Promise((resolve) => setTimeout(resolve, 2000));
    };

    return (
        <SimplePullToRefresh
            onRefresh={handleRefresh}
            className="pull-to-refresh"
            isPullable={touch}
            pullingContent={<></>}
        >
            <>{children}</>
        </SimplePullToRefresh>
    );
}

import { redirect } from "@/router";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useMemo, useState, type PropsWithChildren } from "react";

const storage = makeDefaultStorage({
    idbName: "stackables-v1", // The name of the IndexedDB database
    maxAge: 7, // The maximum age of the persisted data in days
});

interface TokenDecoded {
    iss: "https://identity.sdk42.com";
    jti: string;
    sub: string;
    iat: number;
    exp: number;
    email: string;
    csrf: string;
    counter: number;
    scopes: string[];
    ses?: number;
    td?: boolean;
    name?: string;
}

export interface AuthContextInterface {
    isAuthenticated: boolean;
    token: TokenDecoded | undefined;
    logout: () => void;
    refresh: (force?: boolean) => void;
    getTokenDigest: (force?: boolean) => string | undefined;
}

export const AuthContext = React.createContext<AuthContextInterface>(undefined as unknown as AuthContextInterface);

export const JWT_COOKIE_NAME = "accessToken.body";

export function AuthContextProvider(props: PropsWithChildren) {
    const [contextTime, setContextTime] = useState<number>(Date.now());

    const contextValue = useMemo<AuthContextInterface>(() => {
        let tokenText = cookie.get(JWT_COOKIE_NAME);

        const tokenDecoded = (force?: boolean) => {
            const tokenString = force ? cookie.get(JWT_COOKIE_NAME) : tokenText;

            if (tokenString !== tokenText) {
                tokenText = tokenString;
            }

            if (!tokenString) {
                return undefined;
            }

            try {
                return jwtDecode<TokenDecoded>(tokenString);
            } catch {
                return undefined;
            }
        };

        return {
            get isAuthenticated() {
                return tokenDecoded() !== undefined;
            },
            get token() {
                return tokenDecoded();
            },
            contextTime,
            logout: async () => {
                cookie.remove(JWT_COOKIE_NAME, {
                    // FIXME: THIS NEED adjusting
                    // domain: cookieDomain(),
                    // secure: matchEnvironment("development") ? false : true,
                    httpOnly: false,
                });

                contextValue.refresh();
                setTimeout(() => storage.clear(), 100);

                redirect("/");
            },
            refresh: (force = false) => {
                const tokenTextUpdated = cookie.get(JWT_COOKIE_NAME);
                if (tokenTextUpdated !== tokenText || force) {
                    setContextTime(Date.now());
                }
            },
            getTokenDigest: (force?: boolean) => {
                try {
                    const token = tokenDecoded(force);
                    if (!token) {
                        return undefined;
                    }

                    return `Cookie/v2 ${token.csrf}`;
                } catch {
                    return undefined;
                }
            },
        };
    }, [contextTime]);

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

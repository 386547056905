/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: { input: any; output: any };
};

export type Account = Node & {
    __typename?: "Account";
    _meta?: Maybe<SystemMetaData>;
    allowedIps?: Maybe<Array<AccountAllowedIp>>;
    /** More info about the account for internal users */
    description?: Maybe<Scalars["String"]["output"]>;
    environments?: Maybe<Array<Environment>>;
    features?: Maybe<AccountFeatures>;
    /** Allow creating acount for other users */
    firstAdmin?: Maybe<User>;
    /** Accounts are partitioned by generated primary key to snsure uniform distribution of clients */
    id: Scalars["ID"]["output"];
    invites?: Maybe<Array<AccountInvite>>;
    limits?: Maybe<AccountLimits>;
    lookups?: Maybe<Array<AccountLookup>>;
    /** Accounts can have human readable name for some reason */
    name: Scalars["String"]["output"];
    overlays?: Maybe<Array<Overlay>>;
    packageSettings?: Maybe<PackageSettings>;
    packages?: Maybe<Array<Package>>;
    projects?: Maybe<Array<Project>>;
    resourceGroup?: Maybe<ResourceGroup>;
    /**
     * Unique identifier for the account
     *
     * In case of partitioned entity this will be a as separate system object. We can use fragment to
     * simplify the lookups as with normal entities.
     *
     * Created entity will be something like this
     *
     * @entity (collection: "account")  {
     *   slug: ID! @id(idType: EXTERNAL)
     *   account: Account! @materialize(fragment: $fragment) @cascade(delete)
     * }
     *
     * The difficulty is that query engine needs to respect this additional entity for it to be useful
     * Also it will make updating a bit more tricky as we need to ensure consistency across potentially
     * different partitions
     *
     * actial field in future:
     * slug: String! @unique(fragment: " {name}")
     * @deprecated Use lookup instead
     */
    slug?: Maybe<Scalars["String"]["output"]>;
    storages?: Maybe<Array<Storage>>;
    teams?: Maybe<Array<Team>>;
    users?: Maybe<Array<AccountUser>>;
    /**
     * Account primary website
     * For now not validated, but we might consider validation in the future
     */
    website?: Maybe<Scalars["String"]["output"]>;
};

export type AccountAllowedIpsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountAllowedIp_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountAllowedIp_Sort>;
};

export type AccountEnvironmentsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Environment_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Environment_Sort>;
};

export type AccountInvitesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountInvite_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountInvite_Sort>;
};

export type AccountLookupsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountLookup_Sort>;
};

export type AccountOverlaysArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Overlay_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Overlay_Sort>;
};

export type AccountPackagesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Package_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Package_Sort>;
};

export type AccountProjectsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Project_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Project_Sort>;
};

export type AccountStoragesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Storage_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Storage_Sort>;
};

export type AccountTeamsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Team_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Team_Sort>;
};

export type AccountUsersArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountUser_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountUser_Sort>;
};

export type AccountAllowedIp = Node & {
    __typename?: "AccountAllowedIp";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    lastUsed?: Maybe<Scalars["String"]["output"]>;
    subnet: Scalars["ID"]["output"];
};

export type AccountAllowedIp_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    lastUsed?: InputMaybe<DateTime_Filter>;
    subnet?: InputMaybe<Id_Filter>;
};

export type AccountAllowedIp_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountAllowedIp_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountAllowedIp_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "subnet";

export type AccountFeatures = Node & {
    __typename?: "AccountFeatures";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    aspects?: Maybe<Scalars["Boolean"]["output"]>;
    externalPermissions?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    teams?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AccountFeatures_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    aspects?: InputMaybe<Boolean_Filter>;
    externalPermissions?: InputMaybe<Boolean_Filter>;
    id?: InputMaybe<Id_Filter>;
    teams?: InputMaybe<Boolean_Filter>;
};

export type AccountFeatures_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountFeatures_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountFeatures_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type AccountInvite = Node & {
    __typename?: "AccountInvite";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    codeEmail: Scalars["String"]["output"];
    email: Scalars["ID"]["output"];
    forwardProtection?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    inviter?: Maybe<User>;
    resent?: Maybe<Scalars["String"]["output"]>;
    userByEmail?: Maybe<User>;
};

export type AccountInvite_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    codeEmail?: InputMaybe<String_Filter>;
    email?: InputMaybe<Id_Filter>;
    forwardProtection?: InputMaybe<Boolean_Filter>;
    id?: InputMaybe<Id_Filter>;
    inviter?: InputMaybe<NodeReference_Filter>;
    resent?: InputMaybe<DateTime_Filter>;
    userByEmail?: InputMaybe<NodeReference_Filter>;
};

export type AccountInvite_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountInvite_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountInvite_Sort_Options = "_createdAt" | "_updatedAt" | "codeEmail" | "email" | "id";

export type AccountLimits = {
    __typename?: "AccountLimits";
    requestRate1h?: Maybe<Scalars["Int"]["output"]>;
    requestRate5m?: Maybe<Scalars["Int"]["output"]>;
};

export type AccountLimits_Filter = {
    requestRate1h?: InputMaybe<Int_Filter>;
    requestRate5m?: InputMaybe<Int_Filter>;
};

export type AccountLookup = Node & {
    __typename?: "AccountLookup";
    _meta?: Maybe<SystemMetaData>;
    account: Account;
    id: Scalars["ID"]["output"];
    lookup: Scalars["ID"]["output"];
    type: Scalars["ID"]["output"];
};

export type AccountLookup_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    lookup?: InputMaybe<Id_Filter>;
    type?: InputMaybe<Id_Filter>;
};

export type AccountLookup_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountLookup_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountLookup_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "lookup" | "type";

export type AccountTag = Node & {
    __typename?: "AccountTag";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    canRepeat?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    fixedValues?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    needsValue?: Maybe<Scalars["Boolean"]["output"]>;
    securityDefiner: Scalars["Boolean"]["output"];
    sortOrder?: Maybe<Scalars["Int"]["output"]>;
    values?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type AccountTagValue = {
    __typename?: "AccountTagValue";
    tag?: Maybe<AccountTag>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type AccountTagValue_Filter = {
    tag?: InputMaybe<NodeReference_Filter>;
    value?: InputMaybe<String_Filter>;
};

export type AccountUser = Node & {
    __typename?: "AccountUser";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    tags?: Maybe<Array<AccountTagValue>>;
    teams?: Maybe<Array<TeamMember>>;
    user?: Maybe<User>;
};

export type AccountUserTeamsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TeamMember_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TeamMember_Sort>;
};

export type AccountUser_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type AccountUser_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountUser_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountUser_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "value";

export type Account_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<String_Filter>;
    firstAdmin?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    limits?: InputMaybe<AccountLimits_Filter>;
    name?: InputMaybe<String_Filter>;
    resourceGroup?: InputMaybe<NodeReference_Filter>;
    slug?: InputMaybe<String_Filter>;
    website?: InputMaybe<String_Filter>;
};

export type Account_Sort = {
    by?: InputMaybe<Array<InputMaybe<Account_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Account_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "description"
    | "id"
    | "name"
    | "requestRate1h"
    | "requestRate5m"
    | "slug"
    | "website";

export type ActivateAuthSessionResponse = {
    __typename?: "ActivateAuthSessionResponse";
    pin?: Maybe<Scalars["String"]["output"]>;
    session: AuthSession;
};

export type AuthSession = {
    __typename?: "AuthSession";
    _emailSecretId?: Maybe<Scalars["String"]["output"]>;
    challenge: Scalars["String"]["output"];
    expiresAt: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    state: AuthSessionState;
    type: AuthSessionType;
};

export type AuthSessionState = "completed" | "confirmed" | "pending" | "pin" | "qrcode";

export type AuthSessionType = "email" | "linking" | "passkey";

export type Authenticator = Node & {
    __typename?: "Authenticator";
    _meta?: Maybe<SystemMetaData>;
    darkMode?: Maybe<AuthenticatorTheme>;
    id: Scalars["ID"]["output"];
    lightMode?: Maybe<AuthenticatorTheme>;
    loginWithLabel?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    type: AuthenticatorType;
};

export type AuthenticatorTheme = {
    __typename?: "AuthenticatorTheme";
    backgroundColor?: Maybe<Scalars["String"]["output"]>;
    backgroundHoverColor?: Maybe<Scalars["String"]["output"]>;
    fontFamily?: Maybe<Scalars["String"]["output"]>;
    fontWeight?: Maybe<Scalars["String"]["output"]>;
    iconData?: Maybe<Scalars["String"]["output"]>;
    textColor?: Maybe<Scalars["String"]["output"]>;
};

export type AuthenticatorType = "generic" | "oauth" | "passkey";

export type Boolean_Filter = {
    _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Database = Node & {
    __typename?: "Database";
    _meta?: Maybe<SystemMetaData>;
    connectionStr?: Maybe<Scalars["String"]["output"]>;
    consumer?: Maybe<DatabaseConsumer>;
    database?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    location?: Maybe<Location>;
    port?: Maybe<Scalars["Int"]["output"]>;
    privateIp?: Maybe<Scalars["String"]["output"]>;
    publicIp?: Maybe<Scalars["String"]["output"]>;
    redeploy?: Maybe<Scalars["Boolean"]["output"]>;
    replication?: Maybe<Array<DatabaseStats>>;
    resourceGroup?: Maybe<ResourceGroup>;
    version?: Maybe<Scalars["String"]["output"]>;
};

export type DatabaseConsumer = {
    __typename?: "DatabaseConsumer";
    globalEvents: Scalars["String"]["output"];
    subscription: Scalars["String"]["output"];
    userEvents: Scalars["String"]["output"];
};

export type DatabaseConsumer_Filter = {
    globalEvents?: InputMaybe<String_Filter>;
    subscription?: InputMaybe<String_Filter>;
    userEvents?: InputMaybe<String_Filter>;
};

export type DatabaseStats = {
    __typename?: "DatabaseStats";
    destination: Scalars["String"]["output"];
    distance: Scalars["Int"]["output"];
};

export type Database_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    connectionStr?: InputMaybe<String_Filter>;
    consumer?: InputMaybe<DatabaseConsumer_Filter>;
    database?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    location?: InputMaybe<NodeReference_Filter>;
    port?: InputMaybe<Int_Filter>;
    privateIp?: InputMaybe<String_Filter>;
    publicIp?: InputMaybe<String_Filter>;
    redeploy?: InputMaybe<Boolean_Filter>;
    resourceGroup?: InputMaybe<NodeReference_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type Database_Sort = {
    by?: InputMaybe<Array<InputMaybe<Database_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Database_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "connectionStr"
    | "database"
    | "globalEvents"
    | "id"
    | "port"
    | "privateIp"
    | "publicIp"
    | "subscription"
    | "userEvents"
    | "version";

export type DateTime_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Deployments = "development" | "preview" | "production" | "staging";

export type Deployments_Filter = {
    _eq?: InputMaybe<Deployments>;
};

export type Environment = Node & {
    __typename?: "Environment";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    /** We really should have a system scalar for color select */
    color?: Maybe<Scalars["String"]["output"]>;
    deleteProtection?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    locations?: Maybe<Array<Location>>;
    parent?: Maybe<Environment>;
    status: EnvironmentStatus;
};

export type EnvironmentStatus = "ACTIVE" | "PROCESSING";

export type EnvironmentStatus_Filter = {
    _eq?: InputMaybe<EnvironmentStatus>;
};

export type Environment_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    color?: InputMaybe<String_Filter>;
    deleteProtection?: InputMaybe<Boolean_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    parent?: InputMaybe<NodeReference_Filter>;
    status?: InputMaybe<EnvironmentStatus_Filter>;
};

export type Environment_Sort = {
    by?: InputMaybe<Array<InputMaybe<Environment_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Environment_Sort_Options = "_createdAt" | "_updatedAt" | "color" | "description" | "id";

export type Id_Filter = {
    _eq?: InputMaybe<Scalars["ID"]["input"]>;
    _in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IamSettings = Node & {
    __typename?: "IamSettings";
    _meta?: Maybe<SystemMetaData>;
    id: Scalars["ID"]["output"];
    message?: Maybe<Scalars["String"]["output"]>;
    oauthEnabled: Scalars["Boolean"]["output"];
    passkeyEnabled: Scalars["Boolean"]["output"];
    pinEnabled: Scalars["Boolean"]["output"];
    trustedDeviceEnabled: Scalars["Boolean"]["output"];
};

export type IamSettings_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Int_Filter = {
    _eq?: InputMaybe<Scalars["Int"]["input"]>;
    _gt?: InputMaybe<Scalars["Int"]["input"]>;
    _gte?: InputMaybe<Scalars["Int"]["input"]>;
    _lt?: InputMaybe<Scalars["Int"]["input"]>;
    _lte?: InputMaybe<Scalars["Int"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type JsonObject_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Location = Node & {
    __typename?: "Location";
    _meta?: Maybe<SystemMetaData>;
    gcp: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
};

export type Mutation = {
    __typename?: "Mutation";
    activateAuthSession: ActivateAuthSessionResponse;
    confirmAuthSession: AuthSession;
    createEmailVerificationCode: Scalars["Boolean"]["output"];
    deletePendingEmailVerification?: Maybe<PendingEmailVerification>;
    deleteRefreshToken?: Maybe<RefreshToken>;
    deleteTrustedDevice?: Maybe<TrustedDevice>;
    loginWithEmailCode: Scalars["Boolean"]["output"];
    loginWithPartnerCode: AuthSession;
    loginWithPasskey: Scalars["Boolean"]["output"];
    logout: Scalars["Boolean"]["output"];
    refreshToken: Scalars["Boolean"]["output"];
    registerPartnerCode: Scalars["Boolean"]["output"];
    registerPasskey: Scalars["Boolean"]["output"];
    startAuthSession: AuthSession;
    unlinkIdentityProvider: Scalars["Boolean"]["output"];
    updateAccountEmailSettings: UserLookup;
    updateUserProfile: User;
    verifyEmail: Scalars["Boolean"]["output"];
};

export type MutationActivateAuthSessionArgs = {
    sessionId: Scalars["ID"]["input"];
    state?: InputMaybe<AuthSessionState>;
};

export type MutationConfirmAuthSessionArgs = {
    qrcode: Scalars["String"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationCreateEmailVerificationCodeArgs = {
    email: Scalars["String"]["input"];
};

export type MutationDeletePendingEmailVerificationArgs = {
    having: PendingEmailVerification_Lookup;
    options?: InputMaybe<_DeleteOptions>;
};

export type MutationDeleteRefreshTokenArgs = {
    having: RefreshToken_Lookup;
    options?: InputMaybe<_DeleteOptions>;
};

export type MutationDeleteTrustedDeviceArgs = {
    having: TrustedDevice_Lookup;
    options?: InputMaybe<_DeleteOptions>;
};

export type MutationLoginWithEmailCodeArgs = {
    code: Scalars["String"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationLoginWithPartnerCodeArgs = {
    code: Scalars["String"]["input"];
    provider: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationLoginWithPasskeyArgs = {
    authentication: Scalars["JSONObject"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    sessionId: Scalars["ID"]["input"];
};

export type MutationRegisterPartnerCodeArgs = {
    code: Scalars["String"]["input"];
    provider: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
};

export type MutationRegisterPasskeyArgs = {
    referrer: Scalars["String"]["input"];
    registration: Scalars["JSONObject"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationStartAuthSessionArgs = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUnlinkIdentityProviderArgs = {
    providerId: Scalars["ID"]["input"];
};

export type MutationUpdateAccountEmailSettingsArgs = {
    email: Scalars["String"]["input"];
    pinEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateUserProfileArgs = {
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    primaryEmail: Scalars["String"]["input"];
    profilePicture?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationVerifyEmailArgs = {
    code: Scalars["String"]["input"];
    email: Scalars["String"]["input"];
};

export type Node = {
    _meta?: Maybe<SystemMetaData>;
    id: Scalars["ID"]["output"];
};

export type NodeReference_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type OAuthProvider2 = Node & {
    __typename?: "OAuthProvider2";
    _meta?: Maybe<SystemMetaData>;
    authenticator: Authenticator;
    authorizationEndpoint: Scalars["String"]["output"];
    clientId: Scalars["String"]["output"];
    clientSecret: Scalars["String"]["output"];
    configurationEndpoint?: Maybe<Scalars["String"]["output"]>;
    enabled: Array<Deployments>;
    id: Scalars["ID"]["output"];
    params: Array<OAuthProvider2Params>;
    sort?: Maybe<Scalars["Int"]["output"]>;
    tokenEndpoint: Scalars["String"]["output"];
    userinfoEndpoint?: Maybe<Scalars["String"]["output"]>;
};

export type OAuthProvider2Params = {
    __typename?: "OAuthProvider2Params";
    key: Scalars["String"]["output"];
    value: Scalars["String"]["output"];
};

export type OAuthProvider2Params_Filter = {
    key?: InputMaybe<String_Filter>;
    value?: InputMaybe<String_Filter>;
};

export type OAuthProvider2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    authenticator?: InputMaybe<NodeReference_Filter>;
    authorizationEndpoint?: InputMaybe<String_Filter>;
    clientId?: InputMaybe<String_Filter>;
    clientSecret?: InputMaybe<String_Filter>;
    configurationEndpoint?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    sort?: InputMaybe<Int_Filter>;
    tokenEndpoint?: InputMaybe<String_Filter>;
    userinfoEndpoint?: InputMaybe<String_Filter>;
};

export type OAuthProvider2_Sort = {
    by?: InputMaybe<Array<InputMaybe<OAuthProvider2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type OAuthProvider2_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "authorizationEndpoint"
    | "clientId"
    | "clientSecret"
    | "configurationEndpoint"
    | "id"
    | "key"
    | "sort"
    | "tokenEndpoint"
    | "userinfoEndpoint"
    | "value";

export type Overlay = Node & {
    __typename?: "Overlay";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    approved?: Maybe<Scalars["Boolean"]["output"]>;
    environment: Environment;
    id: Scalars["ID"]["output"];
    keepOpen?: Maybe<Scalars["Boolean"]["output"]>;
    projects?: Maybe<Array<Project>>;
    schemaConsistency?: Maybe<Scalars["Boolean"]["output"]>;
    services?: Maybe<Array<OverlayServiceUpdate>>;
    slug: Scalars["ID"]["output"];
    status?: Maybe<OverlayStatus>;
    statusTime?: Maybe<Scalars["String"]["output"]>;
};

export type OverlayServiceUpdate = {
    __typename?: "OverlayServiceUpdate";
    service: Scalars["String"]["output"];
    version: Scalars["String"]["output"];
};

export type OverlayServiceUpdate_Filter = {
    service?: InputMaybe<String_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type OverlayStatus = "CLOSED" | "COMMIT" | "OPEN" | "ROLLBACK";

export type OverlayStatus_Filter = {
    _eq?: InputMaybe<OverlayStatus>;
};

export type Overlay_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    approved?: InputMaybe<Boolean_Filter>;
    environment?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    keepOpen?: InputMaybe<Boolean_Filter>;
    schemaConsistency?: InputMaybe<Boolean_Filter>;
    slug?: InputMaybe<Id_Filter>;
    status?: InputMaybe<OverlayStatus_Filter>;
    statusTime?: InputMaybe<DateTime_Filter>;
};

export type Overlay_Sort = {
    by?: InputMaybe<Array<InputMaybe<Overlay_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Overlay_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "service" | "slug" | "version";

export type Package = Node & {
    __typename?: "Package";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    channels?: Maybe<Array<PackageChannel>>;
    cloudManager?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    locations?: Maybe<Array<Location>>;
    name: Scalars["ID"]["output"];
    service?: Maybe<Scalars["String"]["output"]>;
    services?: Maybe<Array<PackageLocation>>;
    trafficChannel?: Maybe<Scalars["String"]["output"]>;
    versions?: Maybe<Array<PackageVersion>>;
};

export type PackageServicesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PackageLocation_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PackageLocation_Sort>;
};

export type PackageVersionsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PackageVersion_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PackageVersion_Sort>;
};

export type PackageChannel = {
    __typename?: "PackageChannel";
    channel: Scalars["String"]["output"];
    version: PackageVersion;
};

export type PackageChannel_Filter = {
    channel?: InputMaybe<String_Filter>;
    version?: InputMaybe<NodeReference_Filter>;
};

export type PackageDependency = {
    __typename?: "PackageDependency";
    package: Scalars["String"]["output"];
    version: Scalars["String"]["output"];
};

export type PackageDependency_Filter = {
    package?: InputMaybe<String_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type PackageDistribution = {
    __typename?: "PackageDistribution";
    fileCount?: Maybe<Scalars["Int"]["output"]>;
    integrity: Scalars["String"]["output"];
    shasum: Scalars["String"]["output"];
    unpackedSize?: Maybe<Scalars["Int"]["output"]>;
};

export type PackageDistribution_Filter = {
    fileCount?: InputMaybe<Int_Filter>;
    integrity?: InputMaybe<String_Filter>;
    shasum?: InputMaybe<String_Filter>;
    unpackedSize?: InputMaybe<Int_Filter>;
};

export type PackageLocation = Node & {
    __typename?: "PackageLocation";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    id: Scalars["ID"]["output"];
    location: Location;
    package: Package;
    url: Scalars["String"]["output"];
};

export type PackageLocation_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Id_Filter>;
    location?: InputMaybe<NodeReference_Filter>;
    package?: InputMaybe<PackageLocation_Filter_Package>;
    url?: InputMaybe<String_Filter>;
};

export type PackageLocation_Filter_Package = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    account?: InputMaybe<PackageLocation_Filter_Package_Account>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
};

export type PackageLocation_Filter_Package_Account = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type PackageLocation_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageLocation_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageLocation_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "id"
    | "package__account__id"
    | "package__id"
    | "package__name"
    | "url";

export type PackageRepository = {
    __typename?: "PackageRepository";
    namespace?: Maybe<Scalars["String"]["output"]>;
    repository?: Maybe<Scalars["String"]["output"]>;
    token: Scalars["String"]["output"];
};

export type PackageRepository_Filter = {
    namespace?: InputMaybe<String_Filter>;
    repository?: InputMaybe<String_Filter>;
    token?: InputMaybe<String_Filter>;
};

export type PackageSettings = Node & {
    __typename?: "PackageSettings";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    repositories?: Maybe<Array<PackageRepository>>;
};

export type PackageSettings_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type PackageSettings_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageSettings_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageSettings_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "namespace" | "repository" | "token";

export type PackageVersion = Node & {
    __typename?: "PackageVersion";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    dependencies?: Maybe<Array<PackageDependency>>;
    dist?: Maybe<PackageDistribution>;
    docker?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    package: Package;
    status?: Maybe<PackageVersionStatus>;
    tar?: Maybe<StackablesUpload>;
    version: Scalars["ID"]["output"];
};

export type PackageVersionStatus = "active" | "building" | "delete" | "published";

export type PackageVersionStatus_Filter = {
    _eq?: InputMaybe<PackageVersionStatus>;
};

export type PackageVersion_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    dist?: InputMaybe<PackageDistribution_Filter>;
    docker?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    package?: InputMaybe<PackageVersion_Filter_Package>;
    status?: InputMaybe<PackageVersionStatus_Filter>;
    tar?: InputMaybe<StackablesUpload_Filter>;
    version?: InputMaybe<Id_Filter>;
};

export type PackageVersion_Filter_Package = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
};

export type PackageVersion_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageVersion_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageVersion_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "docker"
    | "fileCount"
    | "id"
    | "integrity"
    | "package"
    | "package__id"
    | "package__name"
    | "shasum"
    | "unpackedSize"
    | "version";

export type Package_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<Package_Filter_Account>;
    cloudManager?: InputMaybe<String_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
    service?: InputMaybe<String_Filter>;
    trafficChannel?: InputMaybe<String_Filter>;
};

export type Package_Filter_Account = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<String_Filter>;
};

export type Package_Sort = {
    by?: InputMaybe<Array<InputMaybe<Package_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Package_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "account__id"
    | "account__slug"
    | "channel"
    | "cloudManager"
    | "description"
    | "id"
    | "name"
    | "service"
    | "trafficChannel";

export type PendingEmailVerification = Node & {
    __typename?: "PendingEmailVerification";
    _meta?: Maybe<SystemMetaData>;
    codeEmail: Scalars["String"]["output"];
    /** @deprecated do not use this field */
    completed?: Maybe<Scalars["Boolean"]["output"]>;
    email: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    /** @deprecated do not use this field */
    user?: Maybe<User>;
};

export type PendingEmailVerification_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    codeEmail?: InputMaybe<String_Filter>;
    completed?: InputMaybe<Boolean_Filter>;
    email?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type PendingEmailVerification_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PendingEmailVerification_Sort = {
    by?: InputMaybe<Array<InputMaybe<PendingEmailVerification_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PendingEmailVerification_Sort_Options = "_createdAt" | "_updatedAt" | "codeEmail" | "email" | "id";

export type Project = Node & {
    __typename?: "Project";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    /** More info about the project for internal users */
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    slug: Scalars["ID"]["output"];
    storages?: Maybe<Array<Storage>>;
};

export type ProjectStoragesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Storage_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Storage_Sort>;
};

export type Project_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<Id_Filter>;
};

export type Project_Sort = {
    by?: InputMaybe<Array<InputMaybe<Project_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Project_Sort_Options = "_createdAt" | "_updatedAt" | "description" | "id" | "slug";

export type Query = {
    __typename?: "Query";
    fetchAuthSession: AuthSession;
    fetchIamSettings?: Maybe<IamSettings>;
    fetchPendingEmailVerification?: Maybe<PendingEmailVerification>;
    fetchUser?: Maybe<User>;
    listOAuthProvider2s?: Maybe<Array<OAuthProvider2>>;
    listPendingEmailVerifications?: Maybe<Array<PendingEmailVerification>>;
    listRefreshTokens?: Maybe<Array<RefreshToken>>;
    listTrustedDevices?: Maybe<Array<TrustedDevice>>;
};

export type QueryFetchAuthSessionArgs = {
    sessionId: Scalars["ID"]["input"];
};

export type QueryFetchIamSettingsArgs = {
    by: IamSettings_Lookup;
};

export type QueryFetchPendingEmailVerificationArgs = {
    by: PendingEmailVerification_Lookup;
};

export type QueryListOAuthProvider2sArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<OAuthProvider2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<OAuthProvider2_Sort>;
};

export type QueryListPendingEmailVerificationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PendingEmailVerification_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PendingEmailVerification_Sort>;
};

export type QueryListRefreshTokensArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<RefreshToken_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<RefreshToken_Sort>;
};

export type QueryListTrustedDevicesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TrustedDevice_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TrustedDevice_Sort>;
};

export type RefreshToken = Node & {
    __typename?: "RefreshToken";
    _meta?: Maybe<SystemMetaData>;
    /** @deprecated Do not use this field */
    deviceInfo?: Maybe<RefreshTokenDeviceInfo>;
    id: Scalars["ID"]["output"];
    /** @deprecated Do not use this field */
    push?: Maybe<RefreshTokenPush>;
    refreshCount: Scalars["Int"]["output"];
    /** @deprecated Do not use this field */
    sessionInfo?: Maybe<RefreshTokenSessionInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
    /** @deprecated Do not use this field */
    user?: Maybe<User>;
    /** @deprecated Do not use this field */
    userLookup?: Maybe<UserLookup>;
};

export type RefreshTokenDeviceInfo = {
    __typename?: "RefreshTokenDeviceInfo";
    browser?: Maybe<Scalars["String"]["output"]>;
    deviceId?: Maybe<Scalars["String"]["output"]>;
    fingerprint?: Maybe<Scalars["String"]["output"]>;
    model?: Maybe<Scalars["String"]["output"]>;
    os?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
    userAgent?: Maybe<Scalars["String"]["output"]>;
    vendor?: Maybe<Scalars["String"]["output"]>;
};

export type RefreshTokenDeviceInfo_Filter = {
    browser?: InputMaybe<String_Filter>;
    deviceId?: InputMaybe<String_Filter>;
    fingerprint?: InputMaybe<String_Filter>;
    model?: InputMaybe<String_Filter>;
    os?: InputMaybe<String_Filter>;
    type?: InputMaybe<String_Filter>;
    userAgent?: InputMaybe<String_Filter>;
    vendor?: InputMaybe<String_Filter>;
};

export type RefreshTokenPush = {
    __typename?: "RefreshTokenPush";
    endpoint: Scalars["String"]["output"];
    expirationTime?: Maybe<Scalars["String"]["output"]>;
    keys: RefreshTokenPushKeys;
};

export type RefreshTokenPushKeys = {
    __typename?: "RefreshTokenPushKeys";
    auth: Scalars["String"]["output"];
    p256dh: Scalars["String"]["output"];
};

export type RefreshTokenPushKeys_Filter = {
    auth?: InputMaybe<String_Filter>;
    p256dh?: InputMaybe<String_Filter>;
};

export type RefreshTokenPush_Filter = {
    endpoint?: InputMaybe<String_Filter>;
    expirationTime?: InputMaybe<DateTime_Filter>;
    keys?: InputMaybe<RefreshTokenPushKeys_Filter>;
};

export type RefreshTokenSessionInfo = {
    __typename?: "RefreshTokenSessionInfo";
    city?: Maybe<Scalars["String"]["output"]>;
    ip?: Maybe<Scalars["String"]["output"]>;
    lastUsed?: Maybe<Scalars["String"]["output"]>;
    region?: Maybe<Scalars["String"]["output"]>;
};

export type RefreshTokenSessionInfo_Filter = {
    city?: InputMaybe<String_Filter>;
    ip?: InputMaybe<String_Filter>;
    lastUsed?: InputMaybe<DateTime_Filter>;
    region?: InputMaybe<String_Filter>;
};

export type RefreshToken_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    deviceInfo?: InputMaybe<RefreshTokenDeviceInfo_Filter>;
    id?: InputMaybe<Id_Filter>;
    push?: InputMaybe<RefreshTokenPush_Filter>;
    refreshCount?: InputMaybe<Int_Filter>;
    sessionInfo?: InputMaybe<RefreshTokenSessionInfo_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
    userLookup?: InputMaybe<NodeReference_Filter>;
};

export type RefreshToken_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type RefreshToken_Sort = {
    by?: InputMaybe<Array<InputMaybe<RefreshToken_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type RefreshToken_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "auth"
    | "browser"
    | "city"
    | "deviceId"
    | "endpoint"
    | "fingerprint"
    | "id"
    | "ip"
    | "model"
    | "os"
    | "p256dh"
    | "refreshCount"
    | "region"
    | "type"
    | "userAgent"
    | "vendor";

export type ResourceGroup = Node & {
    __typename?: "ResourceGroup";
    _meta?: Maybe<SystemMetaData>;
    databases?: Maybe<Array<Database>>;
    id: Scalars["ID"]["output"];
    ip?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    port?: Maybe<Scalars["Int"]["output"]>;
    private?: Maybe<Account>;
};

export type ResourceGroupDatabasesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Database_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Database_Sort>;
};

export type Sort = "asc" | "desc";

export type StackablesUpload = {
    __typename?: "StackablesUpload";
    attachmentId?: Maybe<Scalars["String"]["output"]>;
    encoding?: Maybe<Scalars["String"]["output"]>;
    filename?: Maybe<Scalars["String"]["output"]>;
    mimetype?: Maybe<Scalars["String"]["output"]>;
    url?: Maybe<StackablesUploadUrl>;
};

export type StackablesUploadUrl = {
    __typename?: "StackablesUploadUrl";
    signed?: Maybe<Scalars["String"]["output"]>;
};

export type StackablesUpload_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Storage = Node & {
    __typename?: "Storage";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    collection: Scalars["String"]["output"];
    coreVersion?: Maybe<Scalars["Int"]["output"]>;
    databases: Array<Database>;
    environment: Environment;
    id: Scalars["ID"]["output"];
    operations?: Maybe<Array<StorageOperation>>;
    project: Project;
    redeploy?: Maybe<Scalars["Boolean"]["output"]>;
    schema: Scalars["String"]["output"];
    shardEnd?: Maybe<Scalars["Int"]["output"]>;
    shardStart?: Maybe<Scalars["Int"]["output"]>;
    /** @deprecated Not currently used */
    status?: Maybe<Scalars["String"]["output"]>;
    summary?: Maybe<Array<StorageSummary>>;
    tableVersion?: Maybe<Scalars["Int"]["output"]>;
    /** @deprecated Not currently used */
    version?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperation = {
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperationAdd = StorageOperation & {
    __typename?: "StorageOperationAdd";
    copyFrom?: Maybe<Database>;
    database: Database;
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperationCreate = StorageOperation & {
    __typename?: "StorageOperationCreate";
    databases: Array<Database>;
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageSummary = {
    __typename?: "StorageSummary";
    count?: Maybe<Scalars["Int"]["output"]>;
    database: Database;
    hash?: Maybe<Scalars["String"]["output"]>;
    latest?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
};

export type Storage_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    collection?: InputMaybe<String_Filter>;
    coreVersion?: InputMaybe<Int_Filter>;
    environment?: InputMaybe<Storage_Filter_Environment>;
    id?: InputMaybe<Id_Filter>;
    project?: InputMaybe<Storage_Filter_Project>;
    redeploy?: InputMaybe<Boolean_Filter>;
    schema?: InputMaybe<String_Filter>;
    shardEnd?: InputMaybe<Int_Filter>;
    shardStart?: InputMaybe<Int_Filter>;
    status?: InputMaybe<String_Filter>;
    tableVersion?: InputMaybe<Int_Filter>;
};

export type Storage_Filter_Environment = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type Storage_Filter_Project = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<Id_Filter>;
};

export type Storage_Sort = {
    by?: InputMaybe<Array<InputMaybe<Storage_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Storage_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "collection"
    | "coreVersion"
    | "environment__id"
    | "id"
    | "project__id"
    | "project__slug"
    | "schema"
    | "shardEnd"
    | "shardStart"
    | "status"
    | "tableVersion";

export type String_Filter = {
    _eq?: InputMaybe<Scalars["String"]["input"]>;
    _like?: InputMaybe<Scalars["String"]["input"]>;
    _neq?: InputMaybe<Scalars["String"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SystemMetaData = {
    __typename?: "SystemMetaData";
    createdAt?: Maybe<Scalars["String"]["output"]>;
    cursor?: Maybe<Scalars["String"]["output"]>;
    dataVersion?: Maybe<Scalars["String"]["output"]>;
    deletedAt?: Maybe<Scalars["String"]["output"]>;
    eTag?: Maybe<Scalars["String"]["output"]>;
    externalId?: Maybe<Scalars["String"]["output"]>;
    guid?: Maybe<Scalars["ID"]["output"]>;
    history?: Maybe<Array<SystemMetaDataHistory>>;
    overlay?: Maybe<Scalars["String"]["output"]>;
    partition?: Maybe<Scalars["ID"]["output"]>;
    puid?: Maybe<Scalars["ID"]["output"]>;
    shard?: Maybe<Scalars["Int"]["output"]>;
    updatedAt?: Maybe<Scalars["String"]["output"]>;
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type SystemMetaDataHistory = {
    __typename?: "SystemMetaDataHistory";
    eTag?: Maybe<Scalars["String"]["output"]>;
    patch?: Maybe<Array<SystemMetaDataHistoryChange>>;
};

export type SystemMetaDataHistoryChange = {
    __typename?: "SystemMetaDataHistoryChange";
    op?: Maybe<Scalars["String"]["output"]>;
    path?: Maybe<Scalars["String"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type SystemMetaData_Filter = {
    guid?: InputMaybe<Scalars["ID"]["input"]>;
    puid?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Team = Node & {
    __typename?: "Team";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    members?: Maybe<Array<TeamMember>>;
    team: Scalars["ID"]["output"];
};

export type TeamMembersArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TeamMember_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TeamMember_Sort>;
};

export type TeamMember = Node & {
    __typename?: "TeamMember";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    team: Team;
    user: AccountUser;
};

export type TeamMember_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    team?: InputMaybe<NodeReference_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type TeamMember_Sort = {
    by?: InputMaybe<Array<InputMaybe<TeamMember_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type TeamMember_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type Team_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    team?: InputMaybe<Id_Filter>;
};

export type Team_Sort = {
    by?: InputMaybe<Array<InputMaybe<Team_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Team_Sort_Options = "_createdAt" | "_updatedAt" | "description" | "id" | "team";

export type TrustedDevice = Node & {
    __typename?: "TrustedDevice";
    _meta?: Maybe<SystemMetaData>;
    currentSession?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    push?: Maybe<TrustedDevicePush>;
    refreshCount: Scalars["Int"]["output"];
    usageInfo?: Maybe<UsageInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
};

export type TrustedDevicePush = {
    __typename?: "TrustedDevicePush";
    endpoint: Scalars["String"]["output"];
    expirationTime?: Maybe<Scalars["String"]["output"]>;
    keys: TrustedDevicePushKeys;
};

export type TrustedDevicePushKeys = {
    __typename?: "TrustedDevicePushKeys";
    auth: Scalars["String"]["output"];
    p256dh: Scalars["String"]["output"];
};

export type TrustedDevicePushKeys_Filter = {
    auth?: InputMaybe<String_Filter>;
    p256dh?: InputMaybe<String_Filter>;
};

export type TrustedDevicePush_Filter = {
    endpoint?: InputMaybe<String_Filter>;
    expirationTime?: InputMaybe<DateTime_Filter>;
    keys?: InputMaybe<TrustedDevicePushKeys_Filter>;
};

export type TrustedDevice_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Id_Filter>;
    push?: InputMaybe<TrustedDevicePush_Filter>;
    refreshCount?: InputMaybe<Int_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
};

export type TrustedDevice_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type TrustedDevice_Sort = {
    by?: InputMaybe<Array<InputMaybe<TrustedDevice_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type TrustedDevice_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "auth"
    | "endpoint"
    | "id"
    | "p256dh"
    | "refreshCount";

export type UsageInfo = {
    __typename?: "UsageInfo";
    deviceFormFactor?: Maybe<Scalars["String"]["output"]>;
    deviceName?: Maybe<Scalars["String"]["output"]>;
    ipCountry?: Maybe<Scalars["String"]["output"]>;
    lastUsed?: Maybe<Scalars["String"]["output"]>;
};

export type User = Node & {
    __typename?: "User";
    _meta?: Maybe<SystemMetaData>;
    /** @deprecated Do not use this field */
    acceptedTerms?: Maybe<Scalars["String"]["output"]>;
    accounts?: Maybe<Array<UserAccount>>;
    clearSessions?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Do not use this field */
    email?: Maybe<Scalars["String"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    ipCountryStats?: Maybe<Scalars["JSONObject"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    linkedIdentities?: Maybe<Array<UserLookup>>;
    loginSessions?: Maybe<Array<RefreshToken>>;
    lookups?: Maybe<Array<UserLookup>>;
    /** @deprecated Do not use this field */
    password?: Maybe<Scalars["String"]["output"]>;
    pendingEmails?: Maybe<Array<PendingEmailVerification>>;
    primaryEmail?: Maybe<Scalars["String"]["output"]>;
    profilePicture?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Do not use this field */
    status?: Maybe<Scalars["String"]["output"]>;
    trustedDevices?: Maybe<Array<TrustedDevice>>;
};

export type UserAccountsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserAccount_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserAccount_Sort>;
};

export type UserLinkedIdentitiesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserLookup_Sort>;
};

export type UserLoginSessionsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<RefreshToken_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<RefreshToken_Sort>;
};

export type UserLookupsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserLookup_Sort>;
};

export type UserPendingEmailsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PendingEmailVerification_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PendingEmailVerification_Sort>;
};

export type UserAccount = Node & {
    __typename?: "UserAccount";
    _meta?: Maybe<SystemMetaData>;
    account: AccountUser;
    id: Scalars["ID"]["output"];
    user: User;
};

export type UserAccount_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type UserAccount_Sort = {
    by?: InputMaybe<Array<InputMaybe<UserAccount_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type UserAccount_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type UserLookup = Node & {
    __typename?: "UserLookup";
    _meta?: Maybe<SystemMetaData>;
    authenticator?: Maybe<Authenticator>;
    displayAvatar?: Maybe<Scalars["String"]["output"]>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    email?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["ID"]["output"];
    /** @deprecated Use 'info' instead */
    firstName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    info?: Maybe<UserLookupInfo>;
    /** @deprecated Use 'info' instead */
    lastName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    lastUsed?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    profilePicture?: Maybe<Scalars["String"]["output"]>;
    provider: Scalars["ID"]["output"];
    /** @deprecated Use 'info' instead */
    refreshToken?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    refreshTokenExpires?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    scope?: Maybe<Scalars["String"]["output"]>;
    usageInfo?: Maybe<UsageInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
    user: User;
};

export type UserLookupEmail = {
    __typename?: "UserLookupEmail";
    pinEnabled?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserLookupInfo = UserLookupEmail | UserLookupOauth | UserLookupPasskey;

export type UserLookupOauth = {
    __typename?: "UserLookupOauth";
    email: Scalars["String"]["output"];
    firstName?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    profilePicture?: Maybe<Scalars["String"]["output"]>;
};

export type UserLookupPasskey = {
    __typename?: "UserLookupPasskey";
    algorithm: UserLookupPasskeyAlgo;
    counter: Scalars["Int"]["output"];
    credentialId: Scalars["ID"]["output"];
    publicKey: Scalars["String"]["output"];
};

export type UserLookupPasskeyAlgo = "ES256" | "EdDSA" | "RS256";

export type UserLookup_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    authenticator?: InputMaybe<NodeReference_Filter>;
    displayAvatar?: InputMaybe<String_Filter>;
    displayName?: InputMaybe<String_Filter>;
    email?: InputMaybe<String_Filter>;
    externalId?: InputMaybe<Id_Filter>;
    firstName?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    lastName?: InputMaybe<String_Filter>;
    lastUsed?: InputMaybe<DateTime_Filter>;
    profilePicture?: InputMaybe<String_Filter>;
    provider?: InputMaybe<Id_Filter>;
    refreshToken?: InputMaybe<String_Filter>;
    refreshTokenExpires?: InputMaybe<String_Filter>;
    scope?: InputMaybe<String_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type UserLookup_Sort = {
    by?: InputMaybe<Array<InputMaybe<UserLookup_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type UserLookup_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "displayAvatar"
    | "displayName"
    | "email"
    | "externalId"
    | "firstName"
    | "id"
    | "lastName"
    | "profilePicture"
    | "provider"
    | "refreshToken"
    | "refreshTokenExpires"
    | "scope";

export type User_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type _DeleteOptions = {
    eTag?: InputMaybe<Scalars["String"]["input"]>;
};

export type UseLoginStateProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type UseLoginStateProvidersQuery = {
    __typename: "Query";
    iamSettings?: {
        __typename: "IamSettings";
        id: string;
        pinEnabled: boolean;
        oauthEnabled: boolean;
        passkeyEnabled: boolean;
        trustedDeviceEnabled: boolean;
        message?: string | null;
    } | null;
    listOAuthProviders?: Array<{
        __typename: "OAuthProvider2";
        id: string;
        enabled: Array<Deployments>;
        clientId: string;
        authorizationEndpoint: string;
        params: Array<{ __typename: "OAuthProvider2Params"; key: string; value: string }>;
        authenticator: {
            __typename: "Authenticator";
            id: string;
            name: string;
            loginWithLabel?: string | null;
            darkMode?: {
                __typename: "AuthenticatorTheme";
                fontFamily?: string | null;
                fontWeight?: string | null;
                textColor?: string | null;
                backgroundColor?: string | null;
                backgroundHoverColor?: string | null;
                iconData?: string | null;
            } | null;
            lightMode?: {
                __typename: "AuthenticatorTheme";
                fontFamily?: string | null;
                fontWeight?: string | null;
                textColor?: string | null;
                backgroundColor?: string | null;
                backgroundHoverColor?: string | null;
                iconData?: string | null;
            } | null;
        };
    }> | null;
};

export type LoginWithPartnerCodeMutationVariables = Exact<{
    provider: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    d4d27456?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type LoginWithPartnerCodeMutation = {
    __typename: "Mutation";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        state: AuthSessionState;
        challenge: string;
        expiresAt: string;
        _eid?: string | null;
    };
};

export type FetchAuthSessionQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
    d4d27456?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type FetchAuthSessionQuery = {
    __typename: "Query";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        state: AuthSessionState;
        challenge: string;
        expiresAt: string;
        _eid?: string | null;
    };
};

export type StartAuthSessionMutationVariables = Exact<{
    email?: InputMaybe<Scalars["String"]["input"]>;
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type StartAuthSessionMutation = {
    __typename: "Mutation";
    session: { __typename: "AuthSession"; id: string; type: AuthSessionType; challenge: string; expiresAt: string };
};

export type RegisterPasskeyMutationVariables = Exact<{
    sessionId: Scalars["ID"]["input"];
    referrer: Scalars["String"]["input"];
    registration: Scalars["JSONObject"]["input"];
}>;

export type RegisterPasskeyMutation = { __typename: "Mutation"; registerPasskey: boolean };

export type LoginWithPasskeyMutationVariables = Exact<{
    sessionId: Scalars["ID"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe: Scalars["Boolean"]["input"];
    authentication: Scalars["JSONObject"]["input"];
}>;

export type LoginWithPasskeyMutation = { __typename: "Mutation"; loginWithPasskey: boolean };

export type ActivateAuthSessionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    state: AuthSessionState;
}>;

export type ActivateAuthSessionMutation = {
    __typename: "Mutation";
    activateAuthSession: {
        __typename: "ActivateAuthSessionResponse";
        pin?: string | null;
        session: {
            __typename: "AuthSession";
            id: string;
            type: AuthSessionType;
            challenge: string;
            expiresAt: string;
            state: AuthSessionState;
        };
    };
};

export type ConfirmAuthSessionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    qrcode: Scalars["String"]["input"];
}>;

export type ConfirmAuthSessionMutation = {
    __typename: "Mutation";
    confirmAuthSession: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        challenge: string;
        expiresAt: string;
        state: AuthSessionState;
    };
};

export type FetchAuthSessionWithCodeQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type FetchAuthSessionWithCodeQuery = {
    __typename: "Query";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        challenge: string;
        expiresAt: string;
        state: AuthSessionState;
    };
};

export type LoginWithEmailCodeMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    code: Scalars["String"]["input"];
}>;

export type LoginWithEmailCodeMutation = { __typename: "Mutation"; session: boolean };

export type OauthButtonPropsFragment = {
    __typename: "Authenticator";
    name: string;
    loginWithLabel?: string | null;
    darkMode?: {
        __typename: "AuthenticatorTheme";
        fontFamily?: string | null;
        fontWeight?: string | null;
        textColor?: string | null;
        backgroundColor?: string | null;
        backgroundHoverColor?: string | null;
        iconData?: string | null;
    } | null;
    lightMode?: {
        __typename: "AuthenticatorTheme";
        fontFamily?: string | null;
        fontWeight?: string | null;
        textColor?: string | null;
        backgroundColor?: string | null;
        backgroundHoverColor?: string | null;
        iconData?: string | null;
    } | null;
} & { " $fragmentName"?: "OauthButtonPropsFragment" };

export type UnlinkIdentityProviderMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type UnlinkIdentityProviderMutation = { __typename: "Mutation"; unlinkIdentityProvider: boolean };

export type CreateEmailVerificationCodeMutationVariables = Exact<{
    email: Scalars["String"]["input"];
}>;

export type CreateEmailVerificationCodeMutation = { __typename: "Mutation"; createEmailVerificationCode: boolean };

export type ConfirmEmailVerificationCodeMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
}>;

export type ConfirmEmailVerificationCodeMutation = { __typename: "Mutation"; verifyEmail: boolean };

export type DeletePendingEmailVerificationMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeletePendingEmailVerificationMutation = {
    __typename: "Mutation";
    deletePendingEmailVerification?: { __typename: "PendingEmailVerification"; id: string } | null;
};

export type IdentityProviderEmailFragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: { __typename: "UsageInfo"; lastUsed?: string | null } | null;
    info?:
        | { __typename: "UserLookupEmail"; pinEnabled?: boolean | null }
        | { __typename: "UserLookupOauth" }
        | { __typename: "UserLookupPasskey" }
        | null;
} & { " $fragmentName"?: "IdentityProviderEmailFragment" };

export type PendingEmailVerificationFragment = {
    __typename: "PendingEmailVerification";
    id: string;
    email: string;
    _meta?: { __typename: "SystemMetaData"; createdAt?: string | null } | null;
} & { " $fragmentName"?: "PendingEmailVerificationFragment" };

export type UpdateAccountEmailSettingsMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    pinEnabled: Scalars["Boolean"]["input"];
}>;

export type UpdateAccountEmailSettingsMutation = {
    __typename: "Mutation";
    updateAccountEmailSettings: {
        __typename: "UserLookup";
        id: string;
        info?:
            | { __typename: "UserLookupEmail"; pinEnabled?: boolean | null }
            | { __typename: "UserLookupOauth" }
            | { __typename: "UserLookupPasskey" }
            | null;
    };
};

export type RegisterPartnerCodeMutationVariables = Exact<{
    provider: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
}>;

export type RegisterPartnerCodeMutation = { __typename: "Mutation"; registerPartnerCode: boolean };

export type IdentityProvider2Fragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
    authenticator?: { __typename: "Authenticator"; id: string; name: string } | null;
} & { " $fragmentName"?: "IdentityProvider2Fragment" };

export type IdentityProviderFragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
    authenticator?: {
        __typename: "Authenticator";
        id: string;
        name: string;
        loginWithLabel?: string | null;
        darkMode?: {
            __typename: "AuthenticatorTheme";
            fontFamily?: string | null;
            fontWeight?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            iconData?: string | null;
        } | null;
        lightMode?: {
            __typename: "AuthenticatorTheme";
            fontFamily?: string | null;
            fontWeight?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            iconData?: string | null;
        } | null;
    } | null;
} & { " $fragmentName"?: "IdentityProviderFragment" };

export type DeleteTrustedDeviceMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeleteTrustedDeviceMutation = {
    __typename: "Mutation";
    deleteTrustedDevice?: { __typename: "TrustedDevice"; id: string } | null;
};

export type TrustedDeviceListFragment = {
    __typename: "TrustedDevice";
    id: string;
    currentSession?: boolean | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
} & { " $fragmentName"?: "TrustedDeviceListFragment" };

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename: "Mutation"; logout: boolean };

export type UseMe_QueryQueryVariables = Exact<{ [key: string]: never }>;

export type UseMe_QueryQuery = {
    __typename: "Query";
    user?: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
    } | null;
};

export type RefreshLoginMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshLoginMutation = { __typename: "Mutation"; refreshToken: boolean };

export type SettingsMePage_InfoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsMePage_InfoQueryQuery = {
    __typename: "Query";
    user?: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
        linkedIdentities?: Array<
            { __typename: "UserLookup"; id: string; provider: string } & {
                " $fragmentRefs"?: {
                    IdentityProviderFragment: IdentityProviderFragment;
                    IdentityProvider2Fragment: IdentityProvider2Fragment;
                    IdentityProviderEmailFragment: IdentityProviderEmailFragment;
                };
            }
        > | null;
    } | null;
    tokens?: Array<
        { __typename: "TrustedDevice" } & {
            " $fragmentRefs"?: { TrustedDeviceListFragment: TrustedDeviceListFragment };
        }
    > | null;
    pending?: Array<
        { __typename: "PendingEmailVerification" } & {
            " $fragmentRefs"?: { PendingEmailVerificationFragment: PendingEmailVerificationFragment };
        }
    > | null;
};

export type UpdateUserProfileMutationVariables = Exact<{
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    primaryEmail: Scalars["String"]["input"];
    profilePicture?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserProfileMutation = {
    __typename: "Mutation";
    updateUserProfile: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
    };
};

export const OauthButtonPropsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "OauthButtonProps" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Authenticator" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "darkMode" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundHoverColor" } },
                                { kind: "Field", name: { kind: "Name", value: "iconData" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "lightMode" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundHoverColor" } },
                                { kind: "Field", name: { kind: "Name", value: "iconData" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OauthButtonPropsFragment, unknown>;
export const IdentityProviderEmailFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProviderEmail" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "info" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "UserLookupEmail" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProviderEmailFragment, unknown>;
export const PendingEmailVerificationFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PendingEmailVerification" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PendingEmailVerification" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "email" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "_meta" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PendingEmailVerificationFragment, unknown>;
export const IdentityProvider2FragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider2" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProvider2Fragment, unknown>;
export const IdentityProviderFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProviderFragment, unknown>;
export const TrustedDeviceListFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "TrustedDeviceList" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TrustedDevice" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "currentSession" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TrustedDeviceListFragment, unknown>;
export const UseLoginStateProvidersDocument = {
    __meta__: { hash: "909571defba7fc5ff19c7b8c03d4eab93ed39e37" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UseLoginStateProviders" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "iamSettings" },
                        name: { kind: "Name", value: "fetchIamSettings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "by" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "StringValue", value: "IamSettings", block: false },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "oauthEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "passkeyEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "trustedDeviceEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "message" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "listOAuthProviders" },
                        name: { kind: "Name", value: "listOAuthProvider2s" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: {
                                                kind: "ListValue",
                                                values: [{ kind: "EnumValue", value: "sort" }],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "asc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                                { kind: "Field", name: { kind: "Name", value: "clientId" } },
                                { kind: "Field", name: { kind: "Name", value: "authorizationEndpoint" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "params" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "key" } },
                                            { kind: "Field", name: { kind: "Name", value: "value" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authenticator" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "name" } },
                                            { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "darkMode" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                                        { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundColor" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundHoverColor" },
                                                        },
                                                        { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lightMode" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                                        { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundColor" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundHoverColor" },
                                                        },
                                                        { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseLoginStateProvidersQuery, UseLoginStateProvidersQueryVariables>;
export const LoginWithPartnerCodeDocument = {
    __meta__: { hash: "16b6092c97d22bb172dfcf7d506872e4776cff34" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithPartnerCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "d4d27456" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "loginWithPartnerCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "provider" },
                                value: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                {
                                    kind: "InlineFragment",
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: { kind: "Name", value: "include" },
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: { kind: "Name", value: "if" },
                                                    value: {
                                                        kind: "Variable",
                                                        name: { kind: "Name", value: "d4d27456" },
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "Field",
                                                alias: { kind: "Name", value: "_eid" },
                                                name: { kind: "Name", value: "_emailSecretId" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithPartnerCodeMutation, LoginWithPartnerCodeMutationVariables>;
export const FetchAuthSessionDocument = {
    __meta__: { hash: "bd387ac4eedee968af4ee4919845274a92c61128" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "FetchAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "d4d27456" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "fetchAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                {
                                    kind: "InlineFragment",
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: { kind: "Name", value: "include" },
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: { kind: "Name", value: "if" },
                                                    value: {
                                                        kind: "Variable",
                                                        name: { kind: "Name", value: "d4d27456" },
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "Field",
                                                alias: { kind: "Name", value: "_eid" },
                                                name: { kind: "Name", value: "_emailSecretId" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FetchAuthSessionQuery, FetchAuthSessionQueryVariables>;
export const StartAuthSessionDocument = {
    __meta__: { hash: "d4583ee56946dd0f54fc9863001a8c9ed1e64516" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "StartAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "startAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StartAuthSessionMutation, StartAuthSessionMutationVariables>;
export const RegisterPasskeyDocument = {
    __meta__: { hash: "5f01940da24d3ac264fa1656f6a0112357cbe882" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RegisterPasskey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "registration" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "JSONObject" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "registerPasskey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "registration" },
                                value: { kind: "Variable", name: { kind: "Name", value: "registration" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegisterPasskeyMutation, RegisterPasskeyMutationVariables>;
export const LoginWithPasskeyDocument = {
    __meta__: { hash: "ef7b5dd6dcba1a3d4b24d6a230030cc58119d930" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithPasskey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "authentication" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "JSONObject" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "loginWithPasskey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "authentication" },
                                value: { kind: "Variable", name: { kind: "Name", value: "authentication" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithPasskeyMutation, LoginWithPasskeyMutationVariables>;
export const ActivateAuthSessionDocument = {
    __meta__: { hash: "f6b263cef90b0e6f93e8fa2b0bcfe458a5c8f05c" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ActivateAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "state" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "AuthSessionState" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "activateAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "state" },
                                value: { kind: "Variable", name: { kind: "Name", value: "state" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "pin" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "session" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "type" } },
                                            { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                            { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                            { kind: "Field", name: { kind: "Name", value: "state" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActivateAuthSessionMutation, ActivateAuthSessionMutationVariables>;
export const ConfirmAuthSessionDocument = {
    __meta__: { hash: "b883905742475db596842a9f220503eef79602ab" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "qrcode" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "qrcode" },
                                value: { kind: "Variable", name: { kind: "Name", value: "qrcode" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmAuthSessionMutation, ConfirmAuthSessionMutationVariables>;
export const FetchAuthSessionWithCodeDocument = {
    __meta__: { hash: "027d20940b682e095f8b6c84bcc51bba353678dd" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "FetchAuthSessionWithCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "fetchAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FetchAuthSessionWithCodeQuery, FetchAuthSessionWithCodeQueryVariables>;
export const LoginWithEmailCodeDocument = {
    __meta__: { hash: "ab65dcdaafe547057b234362e2c369e48e79b4f4" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithEmailCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "loginWithEmailCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithEmailCodeMutation, LoginWithEmailCodeMutationVariables>;
export const UnlinkIdentityProviderDocument = {
    __meta__: { hash: "ecca7c627cf0e8c20503239a4409baad0f49badf" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UnlinkIdentityProvider" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "unlinkIdentityProvider" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "providerId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UnlinkIdentityProviderMutation, UnlinkIdentityProviderMutationVariables>;
export const CreateEmailVerificationCodeDocument = {
    __meta__: { hash: "84d2e1c1e1f5fcbe19125796f6b8fb48a3330757" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateEmailVerificationCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createEmailVerificationCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateEmailVerificationCodeMutation, CreateEmailVerificationCodeMutationVariables>;
export const ConfirmEmailVerificationCodeDocument = {
    __meta__: { hash: "bb118200d8a6b040eb5cbbccf252a3ac90bd496b" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmEmailVerificationCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "verifyEmail" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmEmailVerificationCodeMutation, ConfirmEmailVerificationCodeMutationVariables>;
export const DeletePendingEmailVerificationDocument = {
    __meta__: { hash: "11bf7f6b43bdbd6f8bca34ebaeb6690f24fb5e40" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DeletePendingEmailVerification" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deletePendingEmailVerification" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "having" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeletePendingEmailVerificationMutation, DeletePendingEmailVerificationMutationVariables>;
export const UpdateAccountEmailSettingsDocument = {
    __meta__: { hash: "5e8db40880f999dd531ac7195fc009d02f6f78f6" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateAccountEmailSettings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "pinEnabled" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateAccountEmailSettings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "pinEnabled" },
                                value: { kind: "Variable", name: { kind: "Name", value: "pinEnabled" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "info" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: { kind: "Name", value: "UserLookupEmail" },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateAccountEmailSettingsMutation, UpdateAccountEmailSettingsMutationVariables>;
export const RegisterPartnerCodeDocument = {
    __meta__: { hash: "730b62326d39030aaa75536339cc064b7d43f7ec" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RegisterPartnerCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "registerPartnerCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "provider" },
                                value: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegisterPartnerCodeMutation, RegisterPartnerCodeMutationVariables>;
export const DeleteTrustedDeviceDocument = {
    __meta__: { hash: "2ae368ad9c33a7a8d05518e75220a568e8e0614c" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DeleteTrustedDevice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deleteTrustedDevice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "having" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTrustedDeviceMutation, DeleteTrustedDeviceMutationVariables>;
export const LogoutDocument = {
    __meta__: { hash: "f70e5fca72f6a023726bcbebe170ef78b7795a36" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "Logout" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "logout" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const UseMe_QueryDocument = {
    __meta__: { hash: "b9829eef7397f0aad2685617f7f405b42c3848eb" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "useMe_Query" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "user" },
                        name: { kind: "Name", value: "fetchUser" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseMe_QueryQuery, UseMe_QueryQueryVariables>;
export const RefreshLoginDocument = {
    __meta__: { hash: "b0b994a89f26bc93185e8b6156de865d77d0dfdd" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RefreshLogin" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RefreshLoginMutation, RefreshLoginMutationVariables>;
export const SettingsMePage_InfoQueryDocument = {
    __meta__: { hash: "312d25d1e5ea5de4f432f39bc131a327421ae90a" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "SettingsMePage_InfoQuery" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "user" },
                        name: { kind: "Name", value: "fetchUser" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "linkedIdentities" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "provider" } },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProvider" },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProvider2" },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProviderEmail" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "tokens" },
                        name: { kind: "Name", value: "listTrustedDevices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: { kind: "EnumValue", value: "_updatedAt" },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "desc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "FragmentSpread", name: { kind: "Name", value: "TrustedDeviceList" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "pending" },
                        name: { kind: "Name", value: "listPendingEmailVerifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: { kind: "EnumValue", value: "_updatedAt" },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "desc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "FragmentSpread", name: { kind: "Name", value: "PendingEmailVerification" } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider2" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProviderEmail" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "info" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "UserLookupEmail" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "TrustedDeviceList" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TrustedDevice" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "currentSession" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PendingEmailVerification" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PendingEmailVerification" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "email" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "_meta" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SettingsMePage_InfoQueryQuery, SettingsMePage_InfoQueryQueryVariables>;
export const UpdateUserProfileDocument = {
    __meta__: { hash: "57d9a536c7d5806fd67c2cab8705fb2ac24b2672" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "lastName" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "primaryEmail" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "profilePicture" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "firstName" },
                                value: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "lastName" },
                                value: { kind: "Variable", name: { kind: "Name", value: "lastName" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "primaryEmail" },
                                value: { kind: "Variable", name: { kind: "Name", value: "primaryEmail" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "profilePicture" },
                                value: { kind: "Variable", name: { kind: "Name", value: "profilePicture" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/-/auth/callback`
  | `/-/auth/email/:id`
  | `/-/settings/*`
  | `/about`

export type Params = {
  '/-/auth/email/:id': { id: string }
  '/-/settings/*': { '*': string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
